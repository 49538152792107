import {Draw} from 'ol/interaction.js';
import Map from 'ol/Map.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import View from 'ol/View.js';
import Point from 'ol/geom/Point.js';
import {toLonLat, fromLonLat} from 'ol/proj.js';
import Feature from 'ol/Feature.js';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from 'ol/style.js';



const view = new View({
  center: [1028627.563458, 6521296.662223],
  zoom: 5,
  extent: [328627.563458, 5811017.966314, 1816327.095083, 7636950.728974],
});


const raster = new TileLayer({
  source: new OSM(),
});

const source = new VectorSource();
const vector = new VectorLayer({
    source: source,
	style: new Style({
      image: new CircleStyle({
        radius: 20,
        fill: new Fill({
          color: 'rgba(255, 0, 0, 0.5)',
        }),
        stroke: new Stroke({
			color: 'rgba(0, 0, 0, 1.0)',
			width: 2,
		}),		
      }),
      text: new Text({
        text: 'Leitung',
        fill: new Fill({
			color: '#fff',
		}),
        stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.6)',
			width: 3,
		}),
      }),
    }),
});


const source_eq = new VectorSource();
const vector_eq = new VectorLayer({
	source: source_eq,
	style: new Style({
      image: new CircleStyle({
        radius: 20,
        fill: new Fill({
          color: 'rgba(255, 255, 0, 0.5)',
        }),
        stroke: new Stroke({
			color: 'rgba(0, 0, 0, 1.0)',
			width: 2,
		}),		
      }),
      text: new Text({
        text: 'Beben',
        fill: new Fill({
			color: '#fff',
		}),
        stroke: new Stroke({
			color: 'rgba(0, 0, 0, 0.6)',
			width: 3,
		}),
      }),
    }),
});




const map = new Map({
  layers: [raster, vector, vector_eq],
  target: 'map',
  view: view,
});

const modeSiteSelect = document.getElementById('mode_site');
const modeEQSelect = document.getElementById('mode_eq');

const scriptmodeSzen = document.getElementById('scriptmode_szen');
const scriptmodeProb = document.getElementById('scriptmode_prob');



let draw; // global so we can remove them later

function addInteractions() {
	if (modeSiteSelect.checked == true) {
	  draw = new Draw({
		source: source,
		type: 'Point',
	  });
	} 

	if (modeEQSelect.checked == true) {
	  draw = new Draw({
		source: source_eq,
		type: 'Point',
	  });  
	}
	map.addInteraction(draw);


	draw.on('drawend', function (event) {
		const currentFeature = event.feature;
		const point = currentFeature.getGeometry();
		const pointcoords = toLonLat(point.getCoordinates());
		if (modeSiteSelect.checked == true) {
		  source.clear();
		  document.getElementById('ol_point_x').value = pointcoords[0];
		  document.getElementById('ol_point_y').value = pointcoords[1];
		}
		if (modeEQSelect.checked == true) {
		  source_eq.clear();
		  document.getElementById('ol_point_eq_x').value = pointcoords[0];
		  document.getElementById('ol_point_eq_y').value = pointcoords[1];
		}
		const button = document.getElementById('send');
		button.click();
	});  
  
};

modeSiteSelect.onchange = function () {
  map.removeInteraction(draw);
  addInteractions();
};

modeEQSelect.onchange = function () {
  map.removeInteraction(draw);
  addInteractions();
};

scriptmodeSzen.onchange = function () {
	vector_eq.setVisible(true);
	const button = document.getElementById('send');
	button.click();
};

scriptmodeProb.onchange = function () {
	vector_eq.setVisible(false);
	const button = document.getElementById('send');
	button.click();
};

function setStartCoords() {
	const map_zoom = document.getElementById('ol_map_zoom').value;
	const map_center = [document.getElementById('ol_map_center_x').value, document.getElementById('ol_map_center_y').value];
	const pointcoords = [document.getElementById('ol_point_x').value, document.getElementById('ol_point_y').value];
	const pointcoords_eq = [document.getElementById('ol_point_eq_x').value, document.getElementById('ol_point_eq_y').value];
	const PointFeature = new Feature({
		geometry: new Point(fromLonLat(pointcoords)),
	});
	const PointFeature_eq = new Feature({
		geometry: new Point(fromLonLat(pointcoords_eq)),
	});	
	view.setCenter(fromLonLat(map_center));
	view.setZoom(map_zoom);
  	
	source.addFeature(PointFeature);
	source_eq.addFeature(PointFeature_eq);
	
	if (scriptmodeProb.checked == true) {
		vector_eq.setVisible(false)
	}
};


addInteractions();
setStartCoords();





map.on('moveend', function (event) {
	const center = toLonLat(map.getView().getCenter());
	const zoom = map.getView().getZoom();
	document.getElementById('ol_map_center_x').value = center[0];
	document.getElementById('ol_map_center_y').value = center[1];
	document.getElementById('ol_map_zoom').value = zoom;
});
